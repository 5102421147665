import React from 'react';

const UseCases: React.FC = () => {
  const workflows = [
    {
      title: "Onboarding",
      description: "Onboarding automation system",
      icon: "👥",
      videoUrl: "https://www.loom.com/share/a907690a87d4425db85e80c749409a3d?sid=4b39d201-9e10-45e7-88e5-a65d6b63017b"
    },
    {
      title: "Inventory Tracking",
      description: "Real-time inventory tracking automation system",
      icon: "📦",
      videoUrl: "https://www.loom.com/share/ea13a56a838540408a9f808361559694?sid=dbbc08a8-7fa5-4fe9-820e-9ce24db03c34"
    },
    {
      title: "AI Assistant",
      description: "Telegram Bot Assistant",
      icon: "🤖",
      videoUrl: "https://www.loom.com/share/16424df60e7b4ee38d64cc6828f10106"
    }
  ];

  const handleViewExample = (url: string) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  return (
    <section className="py-20 bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-4xl font-bold mb-4 text-center">Examples of workflows we've automated</h2>
        <p className="text-xl text-center mb-12 text-white/80">Real solutions for real businesses</p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {workflows.map((workflow, i) => (
            <div key={i} className="bg-white/10 backdrop-blur-md p-8 rounded-2xl border border-white/20 hover:bg-white/20 transition-all group">
              <div className="text-4xl mb-4">{workflow.icon}</div>
              <h3 className="text-2xl font-bold mb-3">{workflow.title}</h3>
              <p className="text-white/80 mb-4">{workflow.description}</p>
              <button 
                onClick={() => handleViewExample(workflow.videoUrl)}
                className="text-white/60 hover:text-white flex items-center gap-2 transition-colors group-hover:translate-x-2 duration-300"
              >
                View Example
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7" 
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCases; 