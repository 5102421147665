import React from 'react';

const Stats: React.FC = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-6 text-center">
        <div className="mb-16">
          <div className="text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900 mb-6 animate-pulse">
            10,000+
          </div>
          <p className="text-2xl text-gray-600">
            Hours of manual work replaced through our automation solutions
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { number: "95%", label: "Error Reduction" },
            { number: "70%", label: "Time Saved" },
            { number: "45%", label: "Cost Reduction" }
          ].map((stat, i) => (
            <div key={i} className="group bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all hover:-translate-y-1">
              <div className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900 mb-3 group-hover:scale-110 transition-transform">
                {stat.number}
              </div>
              <div className="text-gray-600 text-lg">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Stats; 