import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DesktopHeader: React.FC = () => {
  const navigate = useNavigate();
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const servicesRef = useRef<HTMLLIElement>(null);
  const resourcesRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target as Node)) {
        setIsServicesOpen(false);
      }
      if (resourcesRef.current && !resourcesRef.current.contains(event.target as Node)) {
        setIsResourcesOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleServicesToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsServicesOpen(!isServicesOpen);
    setIsResourcesOpen(false);
  };

  const handleResourcesToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsResourcesOpen(!isResourcesOpen);
    setIsServicesOpen(false);
  };

  const navigateTo = (path: string, e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsServicesOpen(false);
    setIsResourcesOpen(false);
    navigate(path);
  };

  return (
    <header className="bg-blue-900 text-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <a 
            href="/"
            className="flex items-center cursor-pointer"
            onClick={(e) => navigateTo('/', e)}
          >
            <img 
              src="/logo.png" 
              alt="Mufti Automaytion" 
              className="h-10 w-auto"
            />
          </a>
          
          <nav>
            <ul className="flex gap-8 items-center">
              <li ref={servicesRef} className="relative">
                <button 
                  className="hover:text-yellow-400 transition-colors flex items-center gap-1"
                  onClick={handleServicesToggle}
                >
                  Services
                  <svg 
                    className={`w-4 h-4 transition-transform ${isServicesOpen ? 'rotate-180' : ''}`}
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {isServicesOpen && (
                  <div 
                    className="absolute top-full left-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50"
                  >
                    <a 
                      href="/process-automation"
                      className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-900 transition-colors"
                      onClick={(e) => navigateTo('/process-automation', e)}
                    >
                      Process Automation
                    </a>
                    <a 
                      href="/ai-integration"
                      className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-900 transition-colors"
                      onClick={(e) => navigateTo('/ai-integration', e)}
                    >
                      AI Integration
                    </a>
                    <a 
                      href="/ai-consulting"
                      className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-900 transition-colors"
                      onClick={(e) => navigateTo('/ai-consulting', e)}
                    >
                      AI Consulting
                    </a>
                  </div>
                )}
              </li>
              <li ref={resourcesRef} className="relative">
                <button 
                  className="hover:text-yellow-400 transition-colors flex items-center gap-1"
                  onClick={handleResourcesToggle}
                >
                  Resources
                  <svg 
                    className={`w-4 h-4 transition-transform ${isResourcesOpen ? 'rotate-180' : ''}`}
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {isResourcesOpen && (
                  <div 
                    className="absolute top-full left-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50"
                  >
                    <a 
                      href="/white-paper"
                      className="block px-4 py-2 text-gray-800 hover:bg-blue-50 hover:text-blue-900 transition-colors"
                      onClick={(e) => navigateTo('/white-paper', e)}
                    >
                      White Paper
                    </a>
                  </div>
                )}
              </li>
              <li>
                <a 
                  href="/pricing"
                  className="hover:text-yellow-400 transition-colors"
                  onClick={(e) => navigateTo('/pricing', e)}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a 
                  href="/about"
                  className="hover:text-yellow-400 transition-colors"
                  onClick={(e) => navigateTo('/about', e)}
                >
                  About
                </a>
              </li>
              <li>
                <a 
                  href="/blog"
                  className="hover:text-yellow-400 transition-colors"
                  onClick={(e) => navigateTo('/blog', e)}
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/consultation"
                  className="bg-yellow-400 text-black px-4 py-2 rounded-full hover:bg-yellow-300 transition-colors"
                  onClick={(e) => navigateTo('/consultation', e)}
                >
                  Book a Consultation
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default DesktopHeader; 