import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const ThankYou: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-2xl font-semibold text-gray-600 mb-2">Starter Package</h1>
          <h2 className="text-4xl font-bold text-blue-900 mb-4">Thank You for Your Purchase!</h2>
          <p className="text-xl text-gray-600">Your automation journey begins now</p>
        </div>

        {/* Success Message */}
        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <div className="flex items-center justify-center mb-6">
            <CheckCircleIcon className="h-16 w-16 text-green-500" />
          </div>
          <h3 className="text-2xl font-bold text-center text-green-600 mb-4">Payment Successful</h3>
          <p className="text-gray-600 text-center text-lg mb-0">
            We're excited to welcome you to the Mufti Automaytion family! Your Starter Package has been successfully processed, and we're ready to help you begin your automation journey.
          </p>
        </div>

        {/* Next Steps */}
        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <h3 className="text-2xl font-bold text-blue-900 mb-6">Here's What Happens Next:</h3>
          
          <div className="space-y-8">
            {/* Step 1 */}
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-900 text-white rounded-full flex items-center justify-center font-bold">
                1
              </div>
              <div>
                <h4 className="text-xl font-semibold text-blue-900 mb-2">Receipt Confirmation</h4>
                <p className="text-gray-600 mb-2">You'll receive two receipts in your email inbox:</p>
                <ul className="list-disc list-inside text-gray-600 ml-4">
                  <li>One from our payment processor, Pesapal</li>
                  <li>Another from Mufti Automaytion</li>
                </ul>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-900 text-white rounded-full flex items-center justify-center font-bold">
                2
              </div>
              <div>
                <h4 className="text-xl font-semibold text-blue-900 mb-2">Welcome Email</h4>
                <p className="text-gray-600 mb-2">Our CEO will personally send you a welcome email containing:</p>
                <ul className="list-disc list-inside text-gray-600 ml-4">
                  <li>A Calendly link to schedule your one-on-one consultation</li>
                  <li>Important information about your package benefits</li>
                  <li>Contact details for our support team</li>
                </ul>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-900 text-white rounded-full flex items-center justify-center font-bold">
                3
              </div>
              <div>
                <h4 className="text-xl font-semibold text-blue-900 mb-2">Consultation Meeting</h4>
                <p className="text-gray-600 mb-2">During your scheduled meeting, we'll discuss:</p>
                <ul className="list-disc list-inside text-gray-600 ml-4">
                  <li>The specific automations you need</li>
                  <li>Required credentials and access details</li>
                  <li>Timeline for implementation</li>
                  <li>Any questions you might have</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Return Button */}
        <div className="text-center">
          <Link
            to="/"
            className="inline-block bg-gradient-to-r from-blue-900 to-purple-900 text-white px-8 py-4 rounded-full font-semibold hover:opacity-90 transition-opacity"
          >
            Return to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYou; 