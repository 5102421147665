import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PortableText } from '@portabletext/react';
import { sanityFetch, urlFor } from '../lib/sanity';
import { format } from 'date-fns';

interface Post {
  title: string;
  mainImage: any;
  publishedAt: string;
  body: any;
  author: { name: string };
}

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      const query = `
        *[_type == "post" && slug.current == $slug][0] {
          title,
          mainImage,
          publishedAt,
          body,
          author->{ name }
        }
      `;

      try {
        const fetchedPost = await sanityFetch<Post>(query, { slug });
        setPost(fetchedPost);
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
  }

  if (!post) {
    return <div className="min-h-screen flex items-center justify-center">Post not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <article className="max-w-3xl mx-auto px-4 sm:px-6">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">{post.title}</h1>
        
        <div className="flex items-center text-gray-600 mb-8">
          <span>{post.author.name}</span>
          <span className="mx-2">•</span>
          <span>{format(new Date(post.publishedAt), 'MMMM d, yyyy')}</span>
        </div>

        {post.mainImage && (
          <img
            src={urlFor(post.mainImage).width(1200).height(600).url()}
            alt={post.title}
            className="w-full rounded-lg mb-8"
          />
        )}

        <div className="prose prose-lg max-w-none">
          <PortableText value={post.body} />
        </div>
      </article>
    </div>
  );
};

export default BlogPost; 