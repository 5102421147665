import Airtable from 'airtable';
import { WhitePaperFormData } from '../types/whitePaper';

// Add console logs to debug environment variables
if (!process.env.REACT_APP_AIRTABLE_API_KEY || !process.env.REACT_APP_AIRTABLE_BASE_ID) {
  console.error('Missing Airtable environment variables:', {
    apiKey: !!process.env.REACT_APP_AIRTABLE_API_KEY,
    baseId: !!process.env.REACT_APP_AIRTABLE_BASE_ID
  });
}

const base = new Airtable({ 
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY 
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID || '');

// Webhook URL for Make.com (formerly Integromat)
const WEBHOOK_URL = 'https://hook.eu2.make.com/s7ed90gjpgxr35aqy2rkl3drol7q4qjd';

export const submitWhitePaperLead = async (data: WhitePaperFormData) => {
  try {
    // Log the connection attempt
    console.log('Attempting to connect to Airtable with base:', process.env.REACT_APP_AIRTABLE_BASE_ID);
    
    // Create the Airtable record (existing functionality)
    const record = await base('WhitePaper').create([
      {
        fields: {
          'Full Name': data.fullName,
          'Email': data.email,
          'Company Name': data.companyName,
          'Industry': data.industry,
          'Industry Other': data.industryOther,
          'Business Challenge': data.businessChallenge,
          'Using Automation': data.usingAutomation === 'yes' ? 'Yes' : 'No',
          'Support Areas': data.supportAreas,
          'Support Areas Other': data.supportAreasOther,
          'Needs Automation': data.needAutomation === 'yes' ? 'Yes' : 'No',
          'Has Team': data.hasTeam === 'yes' ? 'Yes' : 'No',
          'Receive Updates': data.receiveUpdates,
          'Submission Date': new Date().toISOString().split('T')[0]
        }
      }
    ]);
    
    // Send the same data to the webhook (new functionality)
    try {
      const webhookData = {
        fullName: data.fullName,
        email: data.email,
        companyName: data.companyName,
        industry: data.industry,
        industryOther: data.industryOther || '',
        businessChallenge: data.businessChallenge,
        usingAutomation: data.usingAutomation === 'yes' ? 'Yes' : 'No',
        supportAreas: data.supportAreas,
        supportAreasOther: data.supportAreasOther || '',
        needAutomation: data.needAutomation === 'yes' ? 'Yes' : 'No',
        hasTeam: data.hasTeam === 'yes' ? 'Yes' : 'No',
        receiveUpdates: data.receiveUpdates,
        submissionDate: new Date().toISOString().split('T')[0],
        recordId: record[0].id // Include the Airtable record ID for reference
      };
      
      const webhookResponse = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(webhookData)
      });
      
      if (!webhookResponse.ok) {
        console.warn('Webhook submission warning:', await webhookResponse.text());
      } else {
        console.log('Webhook submission successful');
      }
    } catch (webhookError) {
      // Log webhook errors but don't throw - we still want to return the Airtable record
      console.warn('Webhook submission error:', webhookError);
    }
    
    // Return the Airtable record as before
    return record;
  } catch (error) {
    // Enhanced error logging (existing functionality)
    console.error('Airtable submission error details:', {
      error,
      baseId: process.env.REACT_APP_AIRTABLE_BASE_ID,
      hasApiKey: !!process.env.REACT_APP_AIRTABLE_API_KEY
    });
    throw error;
  }
};