import React from 'react';

const Technologies: React.FC = () => {
  const technologies = [
    {
      title: "ChatGPT",
      description: "ChatGPT is an artificial intelligence chatbot developed by OpenAI.",
      icon: "/icons/chatgpt-logo.png"
    },
    {
      title: "DeepSeek",
      description: "DeepSeek is the efficient and powerful Chinese AI model that has taken the technology industry by storm.",
      icon: "/icons/deepseek-logo.png"
    },
    {
      title: "Gemini",
      description: "Gemini is Google's official AI project and competitor to Chat GPT.",
      icon: "/icons/gemini-logo.png"
    },
    {
      title: "Make",
      description: "Make.com is a Zapier competitor with a more visual interface and allows larger, more complex workflows.",
      icon: "/icons/make-logo.png"
    },
    {
      title: "n8n",
      description: "n8n is a free, open-source, low-code platform that automates tasks and processes across different applications and services.",
      icon: "/icons/n8n-logo.png"
    },
    {
      title: "Zapier",
      description: "Zapier is a platform that connects your tools into automated workflows.",
      icon: "/icons/zapier-logo.png"
    },
    {
      title: "Python and JavaScript",
      description: "We also use python and JavaScript to write custom scripting so we can automate more complex workflows.",
      icon: "/icons/python-js-logo.png"
    },
    {
      title: "Airtable",
      description: "Airtable is a cloud-based application that combines spreadsheet and database features to create and share relational databases.",
      icon: "/icons/airtable-logo.png"
    },
    {
      title: "M-Pesa",
      description: "M-Pesa is a mobile money transfer service that allows users to send and receive money, pay bills, and access financial services.",
      icon: "/icons/mpesa-logo.png"
    },
    {
      title: "Pesapal",
      description: "We leverage Pesapal's API & Plugins to integrate payments into your online business with a simple and easy setup.",
      icon: "/icons/pesapal-logo.png"
    },
    {
      title: "Monday.com",
      description: "Monday.com is a cloud-based work operating system (Work OS) that helps teams manage projects, tasks, and processes.",
      icon: "/icons/monday-logo.png"
    },
    {
      title: "Vercel",
      description: "Vercel is a cloud platform that provides the developer tools and cloud infrastructure to build, scale, and secure a faster, more personalized web.",
      icon: "/icons/vercel-logo.png"
    }
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-4xl font-bold mb-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
          Technologies We Use
        </h2>
        <p className="text-xl text-center mb-12 text-gray-600">
          Cutting-edge tools for modern business automation
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {technologies.map((tech, i) => (
            <div key={i} className="group bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all hover:-translate-y-1">
              <div className="h-16 mb-6 flex items-center justify-center group-hover:scale-110 transition-transform">
                <img 
                  src={tech.icon} 
                  alt={`${tech.title} logo`}
                  className="max-h-full w-auto max-w-[120px] object-contain"
                />
              </div>
              <h3 className="text-xl font-bold mb-3 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
                {tech.title}
              </h3>
              <p className="text-gray-600">
                {tech.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Technologies; 