import React from 'react';
import { Link } from 'react-router-dom';

const AIIntegration: React.FC = () => {
  const benefits = [
    {
      title: "Make Smarter Decisions",
      items: [
        "Get data-driven insights for better decision-making",
        "Predict market trends and customer behavior",
        "Optimize resource allocation",
        "Identify new business opportunities"
      ]
    },
    {
      title: "Enhance Customer Experience",
      items: [
        "Provide 24/7 intelligent customer support",
        "Deliver personalized recommendations",
        "Respond to customer needs faster",
        "Build stronger customer relationships"
      ]
    },
    {
      title: "Boost Operational Efficiency",
      items: [
        "Automate complex cognitive tasks",
        "Process and analyze data at scale",
        "Reduce operational costs",
        "Minimize human error"
      ]
    }
  ];

  const solutions = [
    {
      title: "Intelligent Chatbots",
      items: [
        "Customer service automation",
        "Lead qualification",
        "Appointment scheduling",
        "FAQs and support",
        "Available in English and Swahili"
      ]
    },
    {
      title: "Data Analytics & Insights",
      items: [
        "Business intelligence dashboards",
        "Predictive analytics",
        "Customer behavior analysis",
        "Market trend forecasting",
        "Real-time reporting"
      ]
    },
    {
      title: "Document Processing",
      items: [
        "Automated data extraction",
        "Invoice processing",
        "Contract analysis",
        "Form processing",
        "Multi-language support"
      ]
    },
    {
      title: "Image & Video Analysis",
      items: [
        "Product recognition",
        "Quality control",
        "Security monitoring",
        "Visual inspection",
        "Inventory management"
      ]
    },
    {
      title: "Predictive Maintenance",
      items: [
        "Equipment monitoring",
        "Failure prediction",
        "Maintenance scheduling",
        "Cost optimization",
        "Performance tracking"
      ]
    }
  ];

  const industries = [
    {
      title: "Financial Services",
      items: [
        "Credit risk assessment",
        "Fraud detection",
        "Customer segmentation",
        "Transaction monitoring",
        "Investment analysis"
      ]
    },
    {
      title: "Retail & E-commerce",
      items: [
        "Inventory optimization",
        "Price optimization",
        "Customer recommendations",
        "Demand forecasting",
        "Supply chain management"
      ]
    },
    {
      title: "Manufacturing",
      items: [
        "Quality control",
        "Production optimization",
        "Supply chain planning",
        "Equipment maintenance",
        "Waste reduction"
      ]
    },
    {
      title: "Agriculture",
      items: [
        "Crop yield prediction",
        "Disease detection",
        "Weather analysis",
        "Resource optimization",
        "Market price forecasting"
      ]
    }
  ];

  const testimonials = [
    {
      quote: "AI-powered inventory management has reduced our stockouts by 60% and improved our profit margins significantly.",
      author: "Jane Wahome",
      position: "Supply Chain Director",
      company: "Leading Retail Chain"
    },
    {
      quote: "The AI crop prediction system has helped our farmers optimize their planting and harvesting schedules, increasing yields by 35%.",
      author: "Peter Mwangi",
      position: "Operations Manager",
      company: "Agricultural Cooperative"
    },
    {
      quote: "AI-powered claims processing has reduced our processing time from days to hours while improving accuracy.",
      author: "Mary Kariuki",
      position: "Claims Manager",
      company: "Insurance Provider"
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white py-20">
        <div className="max-w-4xl mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">AI Integration</h1>
          <p className="text-xl md:text-2xl font-light">
            Harness the Power of Artificial Intelligence for Your Business
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction */}
        <section className="mb-16">
          <p className="text-lg text-gray-700 mb-8">
            Transform your business capabilities with Mufti Automaytion's AI integration solutions. We make advanced AI technology accessible and practical for Kenyan businesses, helping you stay competitive in the digital age.
          </p>
        </section>

        {/* Benefits */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Why Integrate AI Into Your Business?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{benefit.title}</h3>
                <ul className="space-y-3">
                  {benefit.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* AI Solutions */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our AI Solutions</h2>
          <div className="grid gap-8">
            {solutions.map((solution, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{solution.title}</h3>
                <ul className="space-y-3">
                  {solution.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Industry Solutions */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Industry Solutions</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {industries.map((industry, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{industry.title}</h3>
                <ul className="space-y-3">
                  {industry.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Implementation Process */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Implementation Process</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">1. Assessment</h3>
              <p className="text-lg">
                We evaluate your business needs and identify the most impactful AI applications for your organization.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">2. Solution Design</h3>
              <p className="text-lg">
                Our experts design custom AI solutions that integrate seamlessly with your existing systems.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">3. Development & Testing</h3>
              <p className="text-lg">
                We develop and rigorously test your AI solutions to ensure reliability and accuracy.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">4. Deployment & Training</h3>
              <p className="text-lg">
                We implement the solutions and train your team to maximize their effectiveness.
              </p>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Success Stories</h2>
          <div className="grid gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <p className="text-lg mb-4 italic">"{testimonial.quote}"</p>
                <div>
                  <p className="font-semibold">{testimonial.author}</p>
                  <p className="text-sm text-gray-300">{testimonial.position}</p>
                  <p className="text-sm text-gray-300">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Call to Action */}
        <div className="text-center py-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Ready to Transform Your Business with AI?</h2>
          <p className="text-lg text-gray-700 mb-8">
            Book a free AI readiness assessment and discover how we can help enhance your operations.
          </p>
          <Link
            to="/consultation"
            className="inline-block bg-gradient-to-r from-blue-900 to-purple-900 text-white px-8 py-4 rounded-lg font-semibold hover:opacity-90 transition-opacity"
          >
            Book Your Free Assessment
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AIIntegration; 