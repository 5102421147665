import React from 'react';

const WhyChoose: React.FC = () => {
  const benefits = [
    {
      title: "Get Expert AI and Automation Support Without the Big Price Tag",
      description: "Think of us as your own AI expert team—offering the benefits of a Chief AI & Automation Officer without the high cost.",
      icon: "💰"
    },
    {
      title: "Connect Your Tools Easily",
      description: "We help you bring together your existing apps and tools. With connections to hundreds of services, we make sure your tech works smoothly as one system.",
      icon: "🔄"
    },
    {
      title: "Save Time by Automating Routine Tasks",
      description: "Stop wasting hours on repetitive work. Our automation handles everyday tasks, freeing your team to focus on what really matters.",
      icon: "⏰"
    },
    {
      title: "Reduce Mistakes and Delays",
      description: "Manual work can lead to errors and slowdowns. Our automated processes work quickly and accurately, cutting down on mistakes and keeping things moving.",
      icon: "✅"
    },
    {
      title: "Smart Use of AI and Automation",
      description: "While AI helps analyze data and make decisions, it works best when combined with automation. We set up systems where each part does what it does best—ensuring smooth and efficient operations for your business.",
      icon: "🤖"
    }
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Why Choose Mufti Automaytion?</h2>
          <p className="text-xl text-gray-600">We deliver innovative automation solutions that drive business growth</p>
        </div>
        <div className="flex flex-col md:flex-row gap-12">
          {/* Left side - Titles */}
          <div className="md:w-1/3 md:sticky md:top-20 md:self-start">
            <h2 className="text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
              Why Choose Mufti Automaytion 
            </h2>
            <p className="text-xl text-gray-600">
              Choose Mufti Automaytion to simplify your processes, save time, and reduce costs—all while making your business more efficient.
            </p>
          </div>

          {/* Right side - Benefits */}
          <div className="md:w-2/3 relative">
            <div className="space-y-16">
              {benefits.map((benefit, i) => (
                <div key={i} className="flex gap-6 items-start">
                  <div className="w-12 h-12 flex-shrink-0 flex items-center justify-center text-3xl rounded-full bg-gradient-to-r from-blue-900 to-purple-900 text-white">
                    {benefit.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
                      {benefit.title}
                    </h3>
                    <p className="text-gray-600">
                      {benefit.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Connecting line */}
            <div className="absolute top-0 bottom-0 left-6 w-0.5 bg-gradient-to-b from-blue-900 to-purple-900 -z-0 hidden md:block"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose; 