import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">Last updated: March 10, 2025</p>

          <article className="prose prose-slate lg:prose-lg max-w-none">
            <p>Please read this Privacy Policy carefully before using Our Service.</p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Interpretation and Definitions</h2>
            <h3 className="text-xl font-bold mt-6 mb-3">Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions. 
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-3">Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, 
                where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote 
                for election of directors or other managing authority.
              </li>
              <li><strong>Country</strong> refers to: Kenya</li>
              <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) 
                refers to Mufti Automaytion.
              </li>
              <li>
                <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, 
                or a digital tablet.
              </li>
              <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
              <li><strong>Service</strong> refers to the Website.</li>
              <li>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or 
                from the Service infrastructure itself.
              </li>
              <li>
                <strong>Website</strong> refers to Mufti Automaytion, accessible from https://www.automaytion.co.ke.
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity 
                on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Collecting and Using Your Personal Data</h2>
            <h3 className="text-xl font-bold mt-6 mb-3">Types of Data Collected</h3>
            <p>
              While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used 
              to contact or identify You. Personally identifiable information may include, but is not limited to:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Email address</li>
              <li>First name and last name</li>
            </ul>
            <p>
              Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's 
              Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the 
              time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
            </p>

            <h3 className="text-xl font-bold mt-6 mb-3">Tracking Technologies and Cookies</h3>
            <p>
              We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. 
              Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
              <li>To manage Your Account: to manage Your registration as a user of the Service.</li>
              <li>
                To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, 
                such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, 
                products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
              </li>
              <li>
                To provide You with news, special offers, and general information about other goods, services, and events which we offer 
                that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
              </li>
            </ul>

            <h2 className="text-2xl font-bold mt-8 mb-4">Retention of Your Personal Data</h2>
            <p>
              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. 
              We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, 
              and enforce our legal agreements and policies.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Transfer of Your Personal Data</h2>
            <p>
              Your information, including Personal Data, is processed at the Company's operating offices and in any other places where 
              the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — 
              computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws 
              may differ than those from Your jurisdiction.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Disclosure of Your Personal Data</h2>
            <h3 className="text-xl font-bold mt-6 mb-3">Business Transactions</h3>
            <p>
              If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will provide notice 
              before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-3">Law Enforcement</h3>
            <p>
              Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or 
              in response to valid requests by public authorities (e.g., a court or a government agency).
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Security of Your Personal Data</h2>
            <p>
              The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or 
              method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, 
              We cannot guarantee its absolute security.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Children's Privacy</h2>
            <p>
              Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone 
              under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact 
              Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, 
              We take steps to remove that information from Our servers.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Links to Other Websites</h2>
            <p>
              Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed 
              to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Changes to This Privacy Policy</h2>
            <p>
              We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last 
              updated" date at the top of this Privacy Policy.
            </p>

            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
            <p>If You have any questions about this Privacy Policy, You can contact us:</p>
            <p>By email: <a href="mailto:privacy@automaytion.co.ke" className="text-blue-600 hover:text-blue-800">
              privacy@automaytion.co.ke
            </a></p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;