import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const ThankYouBusiness: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-2xl font-semibold text-gray-600 mb-2">Business Growth Package</h1>
          <h2 className="text-4xl font-bold text-blue-900 mb-4">Thank You for Your Purchase!</h2>
          <p className="text-xl text-gray-600">Take your business to the next level with automation</p>
        </div>

        {/* Success Message */}
        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <div className="flex items-center justify-center mb-6">
            <CheckCircleIcon className="h-16 w-16 text-green-500" />
          </div>
          <h3 className="text-2xl font-bold text-center text-green-600 mb-4">Payment Successful</h3>
          <p className="text-gray-600 text-center text-lg mb-0">
            We're thrilled you've chosen the Business Growth Package! Your investment in automation is a significant step towards scaling your operations efficiently. We're committed to helping you achieve remarkable growth.
          </p>
        </div>

        {/* Next Steps */}
        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <h3 className="text-2xl font-bold text-blue-900 mb-6">Here's What Happens Next:</h3>
          
          <div className="space-y-8">
            {/* Step 1 */}
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-900 text-white rounded-full flex items-center justify-center font-bold">
                1
              </div>
              <div>
                <h4 className="text-xl font-semibold text-blue-900 mb-2">Receipt Confirmation</h4>
                <p className="text-gray-600 mb-2">You'll receive two receipts in your email inbox:</p>
                <ul className="list-disc list-inside text-gray-600 ml-4">
                  <li>One from our payment processor, Pesapal</li>
                  <li>Another from Mufti Automaytion</li>
                </ul>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-900 text-white rounded-full flex items-center justify-center font-bold">
                2
              </div>
              <div>
                <h4 className="text-xl font-semibold text-blue-900 mb-2">Welcome Email</h4>
                <p className="text-gray-600 mb-2">Our CEO will personally send you a welcome email containing:</p>
                <ul className="list-disc list-inside text-gray-600 ml-4">
                  <li>A Calendly link to schedule your priority consultation</li>
                  <li>Detailed overview of your enhanced package benefits</li>
                  <li>Your dedicated account manager's contact information</li>
                </ul>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-4">
              <div className="flex-shrink-0 w-8 h-8 bg-blue-900 text-white rounded-full flex items-center justify-center font-bold">
                3
              </div>
              <div>
                <h4 className="text-xl font-semibold text-blue-900 mb-2">Strategy Consultation</h4>
                <p className="text-gray-600 mb-2">During your comprehensive strategy session, we'll cover:</p>
                <ul className="list-disc list-inside text-gray-600 ml-4">
                  <li>In-depth analysis of your current business processes</li>
                  <li>Custom automation roadmap aligned with your growth goals</li>
                  <li>Required credentials and system access details</li>
                  <li>Implementation timeline and milestone planning</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Return Button */}
        <div className="text-center">
          <Link
            to="/"
            className="inline-block bg-gradient-to-r from-blue-900 to-purple-900 text-white px-8 py-4 rounded-full font-semibold hover:opacity-90 transition-opacity"
          >
            Return to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYouBusiness; 