import React from 'react';
import { Link } from 'react-router-dom';

const ProcessAutomation: React.FC = () => {
  const solutions = [
    {
      title: "Financial Process Automation",
      items: [
        "Invoice processing and matching",
        "Payment reconciliation",
        "Expense management",
        "Financial report generation",
        "Budget tracking and alerts"
      ]
    },
    {
      title: "Customer Service Automation",
      items: [
        "Automated response systems",
        "Customer inquiry routing",
        "Service ticket management",
        "Follow-up scheduling",
        "Customer feedback collection"
      ]
    },
    {
      title: "HR and Administrative Automation",
      items: [
        "Employee onboarding workflows",
        "Leave management",
        "Document processing",
        "Performance review tracking",
        "Training program management"
      ]
    },
    {
      title: "Sales and Marketing Automation",
      items: [
        "Lead capture and routing",
        "Email marketing campaigns",
        "Sales pipeline management",
        "Customer engagement tracking",
        "Marketing analytics reporting"
      ]
    },
    {
      title: "Supply Chain Automation",
      items: [
        "Inventory management",
        "Order processing",
        "Supplier communication",
        "Delivery tracking",
        "Stock level alerts"
      ]
    }
  ];

  const benefits = [
    {
      title: "Save Time and Money",
      items: [
        "Reduce manual data entry by up to 90%",
        "Cut operational costs by 40-60%",
        "Free up your team for high-value work",
        "Accelerate process completion times"
      ]
    },
    {
      title: "Enhance Accuracy and Consistency",
      items: [
        "Eliminate human errors in routine tasks",
        "Ensure compliance with standardized processes",
        "Maintain consistent quality in operations",
        "Create reliable audit trails"
      ]
    },
    {
      title: "Scale Your Operations",
      items: [
        "Handle increased workload without adding staff",
        "Maintain quality as your business grows",
        "Respond quickly to market changes",
        "Deploy new processes rapidly"
      ]
    }
  ];

  const testimonials = [
    {
      quote: "Mufti Automaytion helped us automate our inventory management system, reducing stockouts by 75% and saving us millions in lost sales opportunities.",
      author: "James Kimani",
      position: "Operations Director",
      company: "Local Manufacturing Company"
    },
    {
      quote: "Our loan processing time decreased from 3 days to just 4 hours after implementing Mufti Automaytion's automation solutions.",
      author: "Sarah Ochieng",
      position: "Chief Digital Officer",
      company: "Financial Services Provider"
    },
    {
      quote: "Patient registration and appointment scheduling are now fully automated, improving patient satisfaction and reducing administrative costs by 45%.",
      author: "Dr. Michael Ndung'u",
      position: "Hospital Administrator",
      company: "Healthcare Provider"
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white py-20">
        <div className="max-w-4xl mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Process Automation</h1>
          <p className="text-xl md:text-2xl font-light">
            Transform Your Business Operations with Intelligent Automation
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction */}
        <section className="mb-16">
          <p className="text-lg text-gray-700 mb-8">
            Eliminate manual tasks, reduce errors, and supercharge your team's productivity with Mufti Automaytion's advanced process automation solutions. We help Kenyan businesses work smarter by automating repetitive tasks and streamlining complex workflows.
          </p>
        </section>

        {/* Benefits */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Why Automate Your Processes?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{benefit.title}</h3>
                <ul className="space-y-3">
                  {benefit.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Solutions */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Process Automation Solutions</h2>
          <div className="grid gap-8">
            {solutions.map((solution, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{solution.title}</h3>
                <ul className="space-y-3">
                  {solution.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Implementation Process */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">How We Implement Automation</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">1. Process Analysis</h3>
              <p className="text-lg">
                We start by understanding your current workflows and identifying opportunities for automation that will deliver the highest ROI.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">2. Solution Design</h3>
              <p className="text-lg">
                Our experts design custom automation solutions that integrate seamlessly with your existing systems and work practices.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">3. Implementation</h3>
              <p className="text-lg">
                We deploy your automation solutions with minimal disruption to your operations, ensuring smooth transition and adoption.
              </p>
            </div>
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">4. Training and Support</h3>
              <p className="text-lg">
                Your team receives comprehensive training and ongoing support to maximize the benefits of your new automated processes.
              </p>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Success Stories</h2>
          <div className="grid gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <p className="text-lg mb-4 italic">"{testimonial.quote}"</p>
                <div>
                  <p className="font-semibold">{testimonial.author}</p>
                  <p className="text-sm text-gray-300">{testimonial.position}</p>
                  <p className="text-sm text-gray-300">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Call to Action */}
        <div className="text-center py-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Ready to Transform Your Business?</h2>
          <p className="text-lg text-gray-700 mb-8">
            Book a free consultation with our automation experts and discover how we can help streamline your operations.
          </p>
          <Link
            to="/consultation"
            className="inline-block bg-gradient-to-r from-blue-900 to-purple-900 text-white px-8 py-4 rounded-lg font-semibold hover:opacity-90 transition-opacity"
          >
            Book Your Free Consultation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProcessAutomation; 