import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MobileHeader: React.FC = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigateTo = (path: string) => {
    setIsMobileMenuOpen(false);
    navigate(path);
  };

  return (
    <header className="bg-blue-900 text-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <a 
            href="/"
            className="flex items-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigateTo('/');
            }}
          >
            <img 
              src="/logo.png" 
              alt="Mufti Automaytion" 
              className="h-10 w-auto"
            />
          </a>
          
          {/* Mobile menu button */}
          <button 
            className="text-white focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              {isMobileMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>
        
        {/* Mobile Navigation - Using native details/summary elements */}
        {isMobileMenuOpen && (
          <nav className="mt-4 bg-blue-800 rounded-lg p-4">
            <details className="mb-3">
              <summary className="cursor-pointer py-2 font-medium">Services</summary>
              <ul className="pl-4 mt-2 border-l-2 border-blue-600">
                <li>
                  <a 
                    href="/process-automation"
                    className="block py-2 hover:text-yellow-400"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateTo('/process-automation');
                    }}
                  >
                    Process Automation
                  </a>
                </li>
                <li>
                  <a 
                    href="/ai-integration"
                    className="block py-2 hover:text-yellow-400"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateTo('/ai-integration');
                    }}
                  >
                    AI Integration
                  </a>
                </li>
                <li>
                  <a 
                    href="/ai-consulting"
                    className="block py-2 hover:text-yellow-400"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateTo('/ai-consulting');
                    }}
                  >
                    AI Consulting
                  </a>
                </li>
              </ul>
            </details>
            
            <details className="mb-3">
              <summary className="cursor-pointer py-2 font-medium">Resources</summary>
              <ul className="pl-4 mt-2 border-l-2 border-blue-600">
                <li>
                  <a 
                    href="/white-paper"
                    className="block py-2 hover:text-yellow-400"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateTo('/white-paper');
                    }}
                  >
                    White Paper
                  </a>
                </li>
              </ul>
            </details>
            
            <ul>
              <li className="py-2">
                <a 
                  href="/pricing"
                  className="block hover:text-yellow-400"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateTo('/pricing');
                  }}
                >
                  Pricing
                </a>
              </li>
              <li className="py-2">
                <a 
                  href="/about"
                  className="block hover:text-yellow-400"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateTo('/about');
                  }}
                >
                  About
                </a>
              </li>
              <li className="py-2">
                <a 
                  href="/blog"
                  className="block hover:text-yellow-400"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateTo('/blog');
                  }}
                >
                  Blog
                </a>
              </li>
              <li className="py-4">
                <a
                  href="/consultation"
                  className="block bg-yellow-400 text-black px-4 py-2 rounded-full text-center hover:bg-yellow-300 transition-colors"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateTo('/consultation');
                  }}
                >
                  Book a Consultation
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default MobileHeader; 