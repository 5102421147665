import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Stats from './components/Stats/Stats';
import Process from './components/Process/Process';
import Technologies from './components/Technologies/Technologies';
import UseCases from './components/UseCases/UseCases';
import WhyChoose from './components/WhyChoose/WhyChoose';
import SectionSeparator from './components/SectionSeparator/SectionSeparator';
import Pricing from './components/Pricing/Pricing';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import ConsultationForm from './components/ConsultationForm/ConsultationForm';
import Home from './pages/Home';
import About from './pages/About';
import Consultation from './pages/Consultation';
import ContactPage from './pages/Contact';
import PricingPage from './pages/Pricing';
import ProcessAutomation from './pages/ProcessAutomation';
import AIIntegration from './pages/AIIntegration';
import AIConsulting from './pages/AIConsulting';
import WhitePaper from './pages/WhitePaper';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import ThankYou from './pages/ThankYou';
import ThankYouBusiness from './pages/ThankYouBusiness';
import ThankYouEnterprise from './pages/ThankYouEnterprise';
import ProtectedThankYouRoute from './components/ProtectedThankYouRoute';
import Schedule from './pages/Schedule';

const ScrollToTop: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <ScrollToTop>
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/consultation" element={<Consultation />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/process-automation" element={<ProcessAutomation />} />
              <Route path="/ai-integration" element={<AIIntegration />} />
              <Route path="/ai-consulting" element={<AIConsulting />} />
              <Route path="/white-paper" element={<WhitePaper />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/thank-you" element={
                <ProtectedThankYouRoute>
                  <ThankYou />
                </ProtectedThankYouRoute>
              } />
              <Route path="/thank-you-business" element={
                <ProtectedThankYouRoute>
                  <ThankYouBusiness />
                </ProtectedThankYouRoute>
              } />
              <Route path="/thank-you-enterprise" element={
                <ProtectedThankYouRoute>
                  <ThankYouEnterprise />
                </ProtectedThankYouRoute>
              } />
              <Route path="/schedule" element={<Schedule />} />
            </Routes>
          </main>
          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  );
};

export default App;
