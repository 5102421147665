import React from 'react';
import ConsultationForm from '../components/ConsultationForm/ConsultationForm';

const Consultation: React.FC = () => {
  return (
    <div>
      <ConsultationForm />
    </div>
  );
};

export default Consultation; 