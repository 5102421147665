import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: '8g1v8i1q', // you can find this in sanity.json
  dataset: 'production',
  useCdn: true,
  apiVersion: '2023-05-03', // use current date
  token: process.env.REACT_APP_SANITY_TOKEN, // Add this if you need authenticated requests
}) as any; // Add type assertion here temporarily

const builder = imageUrlBuilder(client);

export function urlFor(source: any) {
  return builder.image(source);
}

// Add type-safe fetch function
export async function sanityFetch<T>(query: string, params?: Record<string, any>): Promise<T> {
  return client.fetch(query, params);
} 