import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { WhitePaperFormData } from '../types/whitePaper';
import { submitWhitePaperLead } from '../services/airtable';

const WhitePaper: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [hasSubmittedBefore, setHasSubmittedBefore] = useState(false);
  const [industry, setIndustry] = useState('');
  const [supportAreas, setSupportAreas] = useState<string[]>([]);
  const formRef = useRef<HTMLDivElement>(null);

  const industries = [
    'Tech',
    'Retail',
    'Finance',
    'Manufacturing',
    'Logistics',
    'Freelance',
    'Other'
  ];

  const supportAreaOptions = [
    'Finance & Payments',
    'CRM',
    'Inventory & Supply Chain',
    'Marketing',
    'Other'
  ];

  // Check for previous submissions on component mount
  useEffect(() => {
    const previousSubmission = localStorage.getItem('whitePaperSubmission');
    if (previousSubmission) {
      const submissionData = JSON.parse(previousSubmission);
      const submissionDate = new Date(submissionData.date);
      const now = new Date();
      // Check if submission was within the last 24 hours
      if (now.getTime() - submissionDate.getTime() < 24 * 60 * 60 * 1000) {
        setHasSubmittedBefore(true);
        setSubmitSuccess(true);
      } else {
        // Clear old submission data
        localStorage.removeItem('whitePaperSubmission');
      }
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Prevent duplicate submissions
    if (isSubmitting || hasSubmittedBefore) {
      return;
    }

    setIsSubmitting(true);
    setSubmitError(null);

    const form = e.currentTarget;
    const formData = new FormData(form);

    const data: WhitePaperFormData = {
      fullName: formData.get('fullName') as string,
      email: formData.get('email') as string,
      companyName: formData.get('companyName') as string,
      industry: formData.get('industry') as string,
      industryOther: formData.get('industry') === 'Other' ? formData.get('industryOther') as string : undefined,
      businessChallenge: formData.get('businessChallenge') as string,
      usingAutomation: formData.get('automation') as 'yes' | 'no',
      supportAreas: Array.from(formData.getAll('supportAreas')) as string[],
      supportAreasOther: formData.getAll('supportAreas').includes('Other') ? formData.get('supportAreasOther') as string : undefined,
      needAutomation: formData.get('needAutomation') as 'yes' | 'no',
      hasTeam: formData.get('hasTeam') as 'yes' | 'no',
      receiveUpdates: formData.get('receiveUpdates') === 'on'
    };

    try {
      await submitWhitePaperLead(data);
      
      // Store submission data in localStorage
      localStorage.setItem('whitePaperSubmission', JSON.stringify({
        email: data.email,
        date: new Date().toISOString()
      }));

      setSubmitSuccess(true);
      setHasSubmittedBefore(true);
      form.reset();
      
      // Optionally, scroll to success message
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      setSubmitError('Failed to submit form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Render success state if already submitted
  if (submitSuccess) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-900/10 to-purple-900/5 py-20">
        <div className="max-w-2xl mx-auto px-4">
          <div className="bg-white rounded-lg shadow-xl p-8 text-center">
            <div className="text-green-500 text-5xl mb-4">✅</div>
            <h2 className="text-2xl font-bold text-blue-900 mb-4">
              Thank You for Your Interest!
            </h2>
            <p className="text-gray-700 mb-6">
              Your white paper has been sent to your email. If you don't see it in your inbox, please check your spam folder.
            </p>
            <p className="text-sm text-gray-500">
              Note: You've already requested the white paper. Please check your email for the download link.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900/10 to-purple-900/5">
      {/* Hero Section */}
      <section className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
                Discover Agentic AI: The Game-Changer for Kenyan Businesses Ready to Dominate Their Markets
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Download Our Free White Paper and Unlock Proven Automation Strategies to Slash Costs, Boost Efficiency, and Outpace Competitors
              </p>
              <div className="flex items-center space-x-4">
                <button 
                  onClick={scrollToForm}
                  className="bg-gradient-to-r from-blue-900 to-purple-900 text-white px-6 py-3 rounded-full flex items-center hover:opacity-90 transition-opacity"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  Get the White Paper
                </button>
              </div>
            </div>
            <div className="hidden md:block">
              <img 
                src="/automation-illustration.png" 
                alt="Automation Illustration" 
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Benefits and Form Section - New Layout */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12">
            {/* Benefits Section - Left Side */}
            <div>
              <div className="space-y-8">
                <h2 className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
                  Why Kenyan Businesses Can't Afford to Ignore This Revolution
                </h2>
                
                <div className="prose prose-lg max-w-none">
                  <p className="text-gray-700 mb-6">
                    Running a business in Kenya's fast-paced economy is tougher than ever. Talent shortages, language barriers, infrastructure hiccups, and regulatory mazes eat into your profits and stall growth. Traditional automation? It's no longer enough.
                  </p>
                  
                  <p className="text-xl font-semibold text-blue-900 mb-6">
                    But what if your business could think, adapt, and collaborate like a team of experts—only faster, cheaper, and error-free?
                  </p>
                  
                  <p className="text-gray-700 mb-8">
                    Agentic AI isn't just another buzzword. It's the future of automation, where multiple AI agents work together like your best employees—drafting, refining, and perfecting every task. And this white paper is your blueprint to harness it.
                  </p>

                  <div className="bg-white p-8 rounded-lg shadow-lg mb-8">
                    <h3 className="text-2xl font-bold text-blue-900 mb-8">What's Inside?</h3>
                    <div className="grid md:grid-cols-2 gap-6">
                      {[
                        {
                          title: "The Agentic AI Advantage",
                          description: "Why collaborative AI systems outperform single tools—and how they're transforming Kenyan businesses right now.",
                          icon: "🔍"
                        },
                        {
                          title: "Real ROI from Real Kenyan Companies",
                          description: "Case studies show 45% cost reductions, 23% higher customer engagement, and 67% fewer errors. See how local SMEs and enterprises are winning.",
                          icon: "📈"
                        },
                        {
                          title: "Kenya-First Solutions",
                          description: "Tailored strategies for multilingual content, compliance, and power/connectivity challenges—no more cookie-cutter fixes.",
                          icon: "🌍"
                        },
                        {
                          title: "Your Toolkit: n8n & Make.com",
                          description: "Low-cost, high-impact platforms designed for Kenya's unique needs. Skip the guesswork and deploy smarter.",
                          icon: "🛠️"
                        },
                        {
                          title: "Avoid Costly Automation Mistakes",
                          description: 'Learn why "basic AI" fails and how to design resilient, adaptive workflows that scale.',
                          icon: "🚫"
                        },
                        {
                          title: "4-Step Implementation Roadmap",
                          description: "From assessment to continuous improvement—exactly how to start, even with limited tech expertise.",
                          icon: "🗺️"
                        },
                        {
                          title: "24/7 Operations Without Overtime",
                          description: "Turn downtime into profit with AI agents that work round-the-clock, slashing labor costs.",
                          icon: "💡"
                        }
                      ].map((item, index) => (
                        <div 
                          key={index} 
                          className="p-6 rounded-lg bg-gradient-to-br from-blue-50 to-purple-50 border border-gray-100 hover:shadow-md transition-shadow"
                        >
                          <div className="text-2xl mb-3">{item.icon}</div>
                          <h4 className="font-semibold text-blue-900 mb-2">{item.title}</h4>
                          <p className="text-gray-600 text-sm">{item.description}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-blue-50 p-8 rounded-lg mb-8">
                    <h3 className="text-2xl font-bold text-blue-900 mb-6">Why This Matters Now</h3>
                    <p className="text-gray-700 mb-6">
                      Kenyan competitors and global players are racing to adopt Agentic AI. The gap between leaders and laggards is widening. Will your business lead—or struggle to catch up?
                    </p>
                    
                    <div className="grid md:grid-cols-3 gap-6 mb-6">
                      {[
                        {
                          title: "Reclaim Hundreds of Hours",
                          description: "Free your team from repetitive tasks and focus on innovation and growth",
                          icon: "⏱️"
                        },
                        {
                          title: "Eradicate Costly Errors",
                          description: "Multi-agent verification cuts mistakes in compliance, finance, and customer service",
                          icon: "✅"
                        },
                        {
                          title: "Scale Without Limits",
                          description: "Grow operations 2x without doubling costs—Agentic AI handles the heavy lifting",
                          icon: "🚀"
                        }
                      ].map((item, index) => (
                        <div 
                          key={index} 
                          className="p-6 rounded-lg bg-white border border-gray-100 hover:shadow-md transition-shadow"
                        >
                          <div className="text-2xl mb-3">{item.icon}</div>
                          <h4 className="font-semibold text-blue-900 mb-2">{item.title}</h4>
                          <p className="text-gray-600 text-sm">{item.description}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-[#facc16] p-8 rounded-lg">
                    <h3 className="text-2xl font-bold text-blue-900 mb-4">Don't Miss Your Chance—Download Free Today!</h3>
                    <div className="bg-white/80 backdrop-blur-sm p-6 rounded-lg">
                      <p className="text-gray-700 mb-4">
                        This isn't just another guide. It's your key to surviving—and thriving—in Kenya's competitive landscape. And for a limited time, it's <strong>100% free</strong>.
                      </p>
                      <p className="text-lg font-semibold text-blue-900 mb-4">
                        Act Now Before Competitors Do!
                      </p>
                      <p className="text-center text-lg font-bold">
                        👉 Get Your Copy Today 👈
                      </p>
                      <p className="text-center text-sm text-gray-600 mt-4 italic">
                        Transform your business with Agentic AI. The future belongs to those who automate smarter.
                      </p>
                      <div className="mt-4 pt-4 border-t border-gray-200">
                        <p className="text-center text-sm font-semibold">Contact Mufti Automaytion</p>
                        <p className="text-center text-sm text-gray-600">
                          +254 706 120 443 | info@automaytion.co.ke | Automaytion.co.ke
                        </p>
                      </div>
                      <p className="text-center text-sm text-gray-500 mt-4 italic">
                        Limited access—Download your white paper now and join Kenya's automation revolution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Section - Right Side */}
            <div ref={formRef}>
              <div className="bg-white rounded-lg shadow-xl p-8 sticky top-8">
                <h3 className="text-2xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-purple-900">
                  Get Your Free Copy
                </h3>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Full Name*</label>
                    <input 
                      name="fullName"
                      required 
                      placeholder="John Doe"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Email Address*</label>
                    <input 
                      type="email" 
                      name="email"
                      required 
                      placeholder="john@company.com"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Company Name*</label>
                    <input 
                      name="companyName"
                      required 
                      placeholder="Your Company Ltd"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Industry*</label>
                    <select 
                      name="industry"
                      required
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900"
                    >
                      <option value="">Select Industry</option>
                      {industries.map((ind) => (
                        <option key={ind} value={ind}>{ind}</option>
                      ))}
                    </select>
                  </div>

                  {industry === 'Other' && (
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">Please specify your industry*</label>
                      <input 
                        name="industryOther"
                        required
                        placeholder="Your industry"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900"
                      />
                    </div>
                  )}

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">What is your biggest business challenge?*</label>
                    <textarea 
                      name="businessChallenge"
                      required 
                      placeholder="Tell us about your current challenges..."
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900 h-32"
                    ></textarea>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Are you currently using any automation tools?*</label>
                    <div className="flex space-x-4">
                      <label className="flex items-center space-x-2">
                        <input type="radio" name="automation" value="yes" className="text-blue-900" />
                        <span>Yes</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input type="radio" name="automation" value="no" className="text-blue-900" />
                        <span>No</span>
                      </label>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <label className="text-sm font-medium text-gray-700">Which areas do you need support in?</label>
                    <div className="space-y-2">
                      {supportAreaOptions.map((area) => (
                        <label key={area} className="flex items-center space-x-2">
                          <input 
                            type="checkbox" 
                            name="supportAreas"
                            value={area}
                            checked={supportAreas.includes(area)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSupportAreas([...supportAreas, area]);
                              } else {
                                setSupportAreas(supportAreas.filter(a => a !== area));
                              }
                            }}
                            className="text-blue-900 rounded" 
                          />
                          <span className="text-sm">{area}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {supportAreas.includes('Other') && (
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-700">Please specify other support areas needed*</label>
                      <input 
                        name="supportAreasOther"
                        required
                        placeholder="Other support areas"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-900 focus:border-blue-900"
                      />
                    </div>
                  )}

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Are you in need of automation solutions for your organization?*</label>
                    <div className="flex space-x-4">
                      <label className="flex items-center space-x-2">
                        <input type="radio" name="needAutomation" value="yes" className="text-blue-900" required />
                        <span>Yes</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input type="radio" name="needAutomation" value="no" className="text-blue-900" required />
                        <span>No</span>
                      </label>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">Do you have an automation team?*</label>
                    <div className="flex space-x-4">
                      <label className="flex items-center space-x-2">
                        <input type="radio" name="hasTeam" value="yes" className="text-blue-900" required />
                        <span>Yes</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <input type="radio" name="hasTeam" value="no" className="text-blue-900" required />
                        <span>No</span>
                      </label>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="flex items-start space-x-2">
                      <input type="checkbox" name="receiveUpdates" className="mt-1 text-blue-900 rounded" />
                      <span className="text-sm text-gray-700">
                        I'd like to receive relevant trusted Automation news and expert advice, straight to my inbox.
                      </span>
                    </label>
                  </div>

                  {submitError && (
                    <div className="text-red-600 text-sm text-center">{submitError}</div>
                  )}

                  <button 
                    type="submit" 
                    disabled={isSubmitting || hasSubmittedBefore}
                    className="w-full bg-gradient-to-r from-blue-900 to-purple-900 text-white py-3 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50"
                  >
                    {isSubmitting ? 'Submitting...' : 'Download White Paper'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhitePaper; 