import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedThankYouRouteProps {
  children: React.ReactNode;
}

const ProtectedThankYouRoute: React.FC<ProtectedThankYouRouteProps> = ({ children }) => {
  const location = useLocation();
  const [isVerifying, setIsVerifying] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyPesapalRedirect = () => {
      // Get referrer information
      const referrer = document.referrer || '';
      
      // Check query parameters from Pesapal
      const params = new URLSearchParams(location.search);
      const pesapalTrackingId = params.get('pesapal_transaction_tracking_id');
      const pesapalMerchantReference = params.get('pesapal_merchant_reference');
      
      // Verify that:
      // 1. User came from Pesapal OR has valid Pesapal query parameters
      // 2. Has necessary payment verification parameters
      const isPesapalReferrer: boolean = referrer.includes('pesapal.com');
      const hasValidParams: boolean = Boolean(pesapalTrackingId && pesapalMerchantReference);
      
      // Simple verification - either came from Pesapal or has valid Pesapal parameters
      const isValid: boolean = isPesapalReferrer || hasValidParams;
      setIsAuthenticated(isValid);
      setIsVerifying(false);
      
      // Store the verification status in session storage to maintain access after page refresh
      if (isValid) {
        sessionStorage.setItem('pesapalPaymentVerified', 'true');
      }
    };

    // Check if already verified in this session
    const isAlreadyVerified = sessionStorage.getItem('pesapalPaymentVerified') === 'true';
    
    if (isAlreadyVerified) {
      setIsAuthenticated(true);
      setIsVerifying(false);
    } else {
      verifyPesapalRedirect();
    }
  }, [location]);

  if (isVerifying) {
    // Show loading while verifying
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-900 mx-auto mb-4"></div>
          <p className="text-gray-600">Verifying your payment...</p>
        </div>
      </div>
    );
  }

  // Redirect to home if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // Allow access to the protected thank you page
  return <>{children}</>;
};

export default ProtectedThankYouRoute; 