import React from 'react';

const Terms: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms and Conditions</h1>
        <div className="bg-white rounded-lg shadow-lg p-8">
          <p className="text-gray-600 mb-8">Last updated: March 10, 2025</p>
          
          <article className="prose prose-slate lg:prose-lg max-w-none">
            <p>Please read these terms and conditions carefully before using Our Service.</p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Interpretation and Definitions</h2>
            <h3 className="text-xl font-bold mt-6 mb-3">Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions. 
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h3 className="text-xl font-bold mt-6 mb-3">Definitions</h3>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, 
                where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote 
                for election of directors or other managing authority.
              </li>
              <li><strong>Country</strong> refers to: Kenya</li>
              <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) 
                refers to Mufti Automaytion.
              </li>
              <li>
                <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, 
                or a digital tablet.
              </li>
              <li><strong>Service</strong> refers to the Website.</li>
              <li>
                <strong>Terms and Conditions</strong> (also referred to as "Terms") mean these Terms and Conditions that form 
                the entire agreement between You and the Company regarding the use of the Service.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> means any services or content (including data, information, 
                products, or services) provided by a third party that may be displayed, included, or made available by the Service.
              </li>
              <li>
                <strong>Website</strong> refers to Mufti Automaytion, accessible from https://www.automaytion.co.ke.
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity 
                on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-8 mb-4">Acknowledgment</h2>
            <p>
              These Terms and Conditions govern the use of this Service and form the agreement between You and the Company. 
              These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
            </p>
            <p>
              Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms apply to all visitors, users, and others who access or use the Service.
            </p>
            <p>
              By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms, You may not access the Service.
            </p>
            <p>
              You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
            </p>
            <p>
              Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Please read Our Privacy Policy carefully before using Our Service.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Links to Other Websites</h2>
            <p>
              Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.
            </p>
            <p>
              The Company has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that the Company shall not be responsible or liable for any damage or loss caused by or in connection with the use of or reliance on any such content, goods, or services available on or through such websites.
            </p>
            <p>
              We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services You visit.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Termination</h2>
            <p>
              We may terminate or suspend Your access immediately, without prior notice or liability, for any reason, including without limitation if You breach these Terms and Conditions.
            </p>
            <p>
              Upon termination, Your right to use the Service will cease immediately.
            </p>
           <h2 className="text-2xl font-bold mt-8 mb-4">Limitation of Liability</h2>
            <p>
              Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of these Terms shall be limited to the amount actually paid by You through the Service or 100 USD if You haven’t purchased anything through the Service.
            </p>
            <p>
              To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages (including loss of profits, data, business interruption, or personal injury) arising out of or related to the use of or inability to use the Service.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">"AS IS" and "AS AVAILABLE" Disclaimer</h2>
            <p>
              The Service is provided "AS IS" and "AS AVAILABLE" without warranty of any kind. To the maximum extent permitted under applicable law, the Company disclaims all warranties, express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </p>
            <p>
              The Company does not warrant that the Service will be uninterrupted, error-free, or free of viruses or harmful components.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion of certain warranties or limitations of liability, so some of the above exclusions and limitations may not apply to You.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Governing Law</h2>
            <p>
              The laws of Kenya, excluding its conflict of law rules, shall govern these Terms and Your use of the Service.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Dispute Resolution</h2>
            <p>
              If You have any dispute concerning the Service, You agree to first try to resolve it informally by contacting the Company.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Severability and Waiver</h2>
            <h3 className="text-xl font-bold mt-6 mb-3">Severability</h3>
            <p>
              If any provision of these Terms is held to be unenforceable or invalid, that provision will be changed and interpreted to accomplish its objectives to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.
            </p>
            <h3 className="text-xl font-bold mt-6 mb-3">Waiver</h3>
            <p>
              Failure to exercise a right or require performance of an obligation under these Terms shall not affect a party’s ability to exercise such right or require such performance at any time thereafter. The waiver of a breach shall not constitute a waiver of any subsequent breach.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Changes to These Terms and Conditions</h2>
            <p>
             We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect.
            </p>
            <p>
             By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, please stop using the Service.
            </p>
            <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
            <p>If You have any questions about these Terms and Conditions, You can contact us:</p>
            <p>By email: <a href="mailto:questions@automaytion.co.ke" className="text-blue-600 hover:text-blue-800">
              questions@automaytion.co.ke
            </a></p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Terms; 