import React from 'react';
import { Link } from 'react-router-dom';

const AIConsulting: React.FC = () => {
  const benefits = [
    {
      title: "Local Expertise, Global Insights",
      items: [
        "Deep understanding of Kenyan business landscape",
        "Access to global AI automation best practices",
        "Industry-specific knowledge and experience",
        "Practical solutions for local infrastructure"
      ]
    },
    {
      title: "Data-Driven Approach",
      items: [
        "Comprehensive business analysis",
        "ROI-focused recommendations",
        "Clear implementation roadmaps",
        "Measurable outcomes"
      ]
    },
    {
      title: "End-to-End Support",
      items: [
        "Strategic planning to implementation",
        "Change management guidance",
        "Staff training and development",
        "Ongoing optimization support"
      ]
    }
  ];

  const process = [
    {
      title: "1. Discovery & Assessment",
      items: [
        "Business process analysis",
        "Technology infrastructure review",
        "Operational efficiency evaluation",
        "Opportunity identification",
        "Cost-benefit analysis"
      ]
    },
    {
      title: "2. Strategy Development",
      items: [
        "Custom automation roadmap",
        "Technology selection guidance",
        "Risk mitigation planning",
        "Resource allocation planning",
        "Timeline development"
      ]
    },
    {
      title: "3. Implementation Planning",
      items: [
        "Detailed project planning",
        "Change management strategy",
        "Training program development",
        "Success metrics definition",
        "ROI tracking framework"
      ]
    },
    {
      title: "4. Ongoing Support",
      items: [
        "Regular progress reviews",
        "Strategy optimization",
        "Performance monitoring",
        "Technology updates guidance",
        "Continuous improvement recommendations"
      ]
    }
  ];

  const services = [
    {
      title: "AI Readiness Assessment",
      items: [
        "Evaluate your organization's AI readiness",
        "Identify quick wins and long-term opportunities",
        "Assess technical infrastructure requirements",
        "Review staff capabilities and training needs",
        "Provide detailed readiness report"
      ]
    },
    {
      title: "Strategic Planning",
      items: [
        "Develop comprehensive AI automation strategy",
        "Create phased implementation roadmap",
        "Plan resource allocation",
        "Define success metrics",
        "Establish governance framework"
      ]
    },
    {
      title: "Technology Selection",
      items: [
        "Evaluate available AI tools and platforms",
        "Recommend suitable technologies",
        "Compare cost-benefit ratios",
        "Assess integration requirements",
        "Plan scaling capabilities"
      ]
    },
    {
      title: "Change Management",
      items: [
        "Develop staff communication strategy",
        "Create training programs",
        "Plan organizational restructuring",
        "Manage resistance to change",
        "Monitor adoption progress"
      ]
    }
  ];

  const industries = [
    {
      title: "Financial Services",
      items: [
        "Digital banking transformation",
        "Automated risk assessment",
        "Customer service automation",
        "Compliance monitoring",
        "Fraud detection systems"
      ]
    },
    {
      title: "Manufacturing",
      items: [
        "Production optimization",
        "Quality control automation",
        "Supply chain integration",
        "Predictive maintenance",
        "Inventory management"
      ]
    },
    {
      title: "Retail & E-commerce",
      items: [
        "Customer experience automation",
        "Inventory optimization",
        "Marketing automation",
        "Sales forecasting",
        "Supply chain management"
      ]
    },
    {
      title: "Agriculture",
      items: [
        "Production planning automation",
        "Market analysis tools",
        "Supply chain optimization",
        "Quality control systems",
        "Distribution automation"
      ]
    }
  ];

  const testimonials = [
    {
      quote: "Mufti Automaytion's consulting helped us identify and implement the right AI solutions, leading to a 40% reduction in processing time.",
      author: "David Njoroge",
      position: "Digital Transformation Director",
      company: "Commercial Bank"
    },
    {
      quote: "Their strategic guidance helped us automate key processes while managing change effectively. Our productivity increased by 50%.",
      author: "Alice Muthoni",
      position: "Operations Manager",
      company: "Manufacturing Company"
    },
    {
      quote: "The consulting team's expertise was invaluable in our digital transformation journey. We've seen a 30% increase in customer satisfaction.",
      author: "John Kamau",
      position: "CEO",
      company: "Retail Chain"
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white py-20">
        <div className="max-w-4xl mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">AI Automation Consulting</h1>
          <p className="text-xl md:text-2xl font-light">
            Strategic Guidance for Your Digital Transformation Journey
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-6 py-12">
        {/* Introduction */}
        <section className="mb-16">
          <p className="text-lg text-gray-700 mb-8">
            Navigate the future of business with confidence. Mufti Automaytion's consulting services help you make informed decisions about AI automation, ensuring your digital transformation delivers real business value.
          </p>
        </section>

        {/* Benefits */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Why Choose Our Consulting Services?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{benefit.title}</h3>
                <ul className="space-y-3">
                  {benefit.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Consulting Process */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Consulting Process</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {process.map((step, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{step.title}</h3>
                <ul className="space-y-3">
                  {step.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Consulting Services */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Consulting Services</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {services.map((service, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <ul className="space-y-3">
                  {service.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Industry Focus */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Industry Focus</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {industries.map((industry, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <h3 className="text-2xl font-bold mb-4">{industry.title}</h3>
                <ul className="space-y-3">
                  {industry.items.map((item, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-yellow-400 mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Testimonials */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Success Stories</h2>
          <div className="grid gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
                <p className="text-lg mb-4 italic">"{testimonial.quote}"</p>
                <div>
                  <p className="font-semibold">{testimonial.author}</p>
                  <p className="text-sm text-gray-300">{testimonial.position}</p>
                  <p className="text-sm text-gray-300">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Call to Action */}
        <div className="text-center py-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Ready to Start Your Digital Transformation?</h2>
          <p className="text-lg text-gray-700 mb-8">
            Book a free consultation with our experts and discover how we can guide your AI automation journey.
          </p>
          <Link
            to="/consultation"
            className="inline-block bg-gradient-to-r from-blue-900 to-purple-900 text-white px-8 py-4 rounded-lg font-semibold hover:opacity-90 transition-opacity"
          >
            Book Your Free Consultation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AIConsulting; 