import React from 'react';
import { Link } from 'react-router-dom';

const About: React.FC = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white py-20">
        <div className="max-w-4xl mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">About Mufti Automaytion: Our Story</h1>
          <p className="text-xl md:text-2xl font-light">
            Transforming Kenyan Businesses Through AI Innovation
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-6 py-12">
        <section className="mb-16">
          <p className="text-lg text-gray-700 mb-8">
            Mufti Automaytion is Kenya's pioneering agentic AI solutions provider, transforming how Kenyan businesses operate through orchestrated AI workflows. Based in Nairobi, we specialize in creating collaborative AI systems where multiple specialized agents work together to complete complex tasks—mirroring human collaboration but at scale and with consistent quality.
          </p>
          <p className="text-lg text-gray-700 mb-8">
            Founded with the vision of making cutting-edge agentic AI accessible to local companies, we design Kenya-first solutions that address unique challenges in infrastructure resilience, linguistic diversity, and regulatory compliance. Our custom implementations using n8n and Make.com platforms enable businesses to achieve higher quality outputs and greater operational efficiency while controlling costs.
          </p>
          <p className="text-lg text-gray-700 mb-8">
            Unlike conventional automation that follows fixed paths, our agentic AI systems adapt their processes based on intermediate results, creating truly intelligent workflows across marketing, customer service, operations management, and finance. We combine global best practices with deep local knowledge to deliver immediate value while building foundations for ongoing innovation.
          </p>
          <p className="text-lg text-gray-700">
            Our team of experts guides clients from initial assessment through implementation and continuous improvement, ensuring measurable ROI within months. At Mufti Automaytion, we're committed to positioning Kenyan businesses at the forefront of this technological revolution, helping them work smarter, not harder in an increasingly competitive marketplace.
          </p>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">What Sets Us Apart</h2>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Local Expertise</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Deep understanding of Kenyan business landscape
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Solutions tailored to local infrastructure
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Team of locally-based AI experts
                </li>
              </ul>
            </div>

            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Global Standards</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Implementation of best practices
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Cutting-edge AI technologies
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  International quality benchmarks
                </li>
              </ul>
            </div>

            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Practical Innovation</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Focus on real-world business results
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Cost-effective solutions with quick ROI
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Scalable implementations
                </li>
              </ul>
            </div>

            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Client-First Approach</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Comprehensive needs assessment
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Custom solutions for your challenges
                </li>
                <li className="flex items-start">
                  <span className="text-yellow-400 mr-2">•</span>
                  Ongoing training and support
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Mission</h2>
          <p className="text-lg text-gray-700">
            To empower Kenyan businesses with accessible, affordable, and effective AI automation solutions that drive growth, improve efficiency, and create sustainable competitive advantages.
          </p>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Vision</h2>
          <p className="text-lg text-gray-700">
            To be the catalyst for digital transformation in East Africa, making advanced AI automation accessible to businesses of all sizes and helping build a more efficient, innovative, and prosperous business ecosystem.
          </p>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Values</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Innovation with Purpose</h3>
              <p className="text-lg">
                We don't just implement technology for technology's sake. Every solution we provide is carefully chosen to address specific business challenges and deliver measurable results.
              </p>
            </div>
            
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Local Impact</h3>
              <p className="text-lg">
                We're committed to strengthening Kenya's business ecosystem by making advanced automation accessible to local companies and helping build local technical expertise.
              </p>
            </div>
            
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Excellence in Delivery</h3>
              <p className="text-lg">
                We maintain the highest standards in our solutions, support, and client relationships, ensuring that every project we undertake delivers real value to our clients.
              </p>
            </div>
            
            <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white rounded-lg p-8">
              <h3 className="text-2xl font-bold mb-4">Continuous Learning</h3>
              <p className="text-lg">
                We stay at the forefront of AI technology while continuously learning from our clients and adapting our solutions to better serve the Kenyan market.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Our Impact</h2>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-900 mb-2">50+</div>
              <div className="text-lg text-gray-700">Businesses Automated</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-900 mb-2">45%</div>
              <div className="text-lg text-gray-700">Average Cost Reduction</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-900 mb-2">10,000+</div>
              <div className="text-lg text-gray-700">Hours Saved</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-blue-900 mb-2">Dozens</div>
              <div className="text-lg text-gray-700">Professionals Trained</div>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <div className="text-center py-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Partner With Us</h2>
          <p className="text-lg text-gray-700 mb-8">
          As AI technology continues to evolve, Mufti Automaytion remains committed to bringing the latest innovations to the Kenyan market in ways that are practical, affordable, and effective. We're excited to be part of Kenya's digital transformation journey and look forward to helping more businesses achieve their full potential through smart automation. Whether you're a small business looking to streamline your operations or a large enterprise seeking comprehensive digital transformation, Mufti Automaytion has the expertise, experience, and commitment to help you succeed.
          </p>
          <Link
            to="/consultation"
            className="bg-yellow-400 text-black px-8 py-4 rounded-full font-semibold hover:bg-yellow-300 transform hover:-translate-y-1 transition-all shadow-lg"
          >
            Book a Consultation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About; 