import React from 'react';
import Hero from '../components/Hero/Hero';
import Stats from '../components/Stats/Stats';
import Process from '../components/Process/Process';
import Technologies from '../components/Technologies/Technologies';
import UseCases from '../components/UseCases/UseCases';
import WhyChoose from '../components/WhyChoose/WhyChoose';

const Home: React.FC = () => {
  return (
    <div>
      <Hero />
      <Stats />
      <Process />
      <Technologies />
      <UseCases />
      <WhyChoose />
    </div>
  );
};

export default Home; 