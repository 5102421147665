import React from 'react';
import Pricing from '../components/Pricing/Pricing';

const PricingPage: React.FC = () => {
  return (
    <div>
      <Pricing />
    </div>
  );
};

export default PricingPage; 