import React from 'react';

const Process: React.FC = () => {
  return (
    <section className="py-20 bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-4xl font-bold mb-12 text-center">Our Process</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { title: "Discovery", description: "We analyze your current processes and identify automation opportunities" },
            { title: "Design", description: "We design custom automation solutions tailored to your needs" },
            { title: "Implementation", description: "We implement and test the automation solutions" }
          ].map((step, i) => (
            <div key={i} className="bg-white/10 backdrop-blur-md p-8 rounded-2xl border border-white/20 hover:bg-white/20 transition-all">
              <div className="text-2xl font-bold mb-4">{step.title}</div>
              <p className="text-white/80">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Process; 