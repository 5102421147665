import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Pricing: React.FC = () => {
  const [showStarterModal, setShowStarterModal] = useState(false);
  const navigate = useNavigate();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<typeof plans[0] | null>(null);

  const plans = [
    {
      title: "Starter Package",
      price: "KES 69,000",
      description: "Perfect for small businesses beginning their automation journey",
      automations: [
        "Email Follow-up System",
        "Meeting Scheduler",
        "Basic Form Processing",
        "Social Media Scheduler (2 platforms)"
      ],
      support: [
        "Email support (48-hour response)",
        "1 hour implementation assistance per month",
        "Monthly system health check",
        "Basic automation templates",
        "1 workflow revision per month"
      ],
      featured: false,
      iframeUrl: "https://store.pesapal.com/embed-code?pageUrl=https://store.pesapal.com/muftiautomaytionstarterpackage"
    },
    {
      title: "Business Growth Package",
      price: "KES 120,000",
      description: "For growing businesses ready to scale operations",
      automations: [
        "Everything in Starter Package",
        "Customer Onboarding Workflow",
        "Lead Qualification System",
        "Document Approval Workflows",
        "Expense Tracking & Reporting",
        "Support Ticket Management"
      ],
      support: [
        "Priority email support (24-hour response)",
        "Live chat support during business hours",
        "3 hours implementation assistance per month",
        "Bi-weekly system health checks",
        "Quarterly strategy session",
        "Full access to automation templates",
        "3 workflow revisions per month"
      ],
      featured: true,
      iframeUrl: "https://store.pesapal.com/embed-code?pageUrl=https://store.pesapal.com/muftiautomaytionbusinessgrowth"
    },
    {
      title: "Enterprise Package",
      price: "KES 250,000",
      description: "Comprehensive solution for established businesses",
      automations: [
        "Everything in Business Growth Package",
        "Advanced Data Synchronization",
        "Custom Reporting Dashboards",
        "Complex Approval Hierarchies",
        "Inventory Management System",
        "Contract Lifecycle Management",
        "Client Relationship Automation",
        "Project Management Automation"
      ],
      support: [
        "24/7 priority support with dedicated manager",
        "10 hours implementation assistance monthly",
        "Weekly system health checks",
        "Monthly strategy sessions",
        "Unlimited workflow revisions",
        "Comprehensive system integration",
        "Custom automation development",
        "Staff training sessions (quarterly)"
      ],
      featured: false,
      iframeUrl: "https://store.pesapal.com/embed-code?pageUrl=https://store.pesapal.com/muftiautomaytionenterprisepackage"
    },
    {
      title: "Custom Solution",
      price: "Custom Pricing",
      description: "Tailored automations for unique business requirements",
      automations: [
        "Industry-specific automation workflows",
        "Integration with your existing systems",
        "Specialized data processing requirements",
        "Custom reporting and analytics",
        "Dedicated deployment and implementation",
        "Bespoke training and documentation"
      ],
      support: [
        "Dedicated project manager",
        "Custom SLA options",
        "On-site implementation support",
        "Personalized onboarding experience"
      ],
      featured: false,
      isCustom: true
    }
  ];

  const handlePurchase = (plan: typeof plans[0]) => {
    if (plan.isCustom) {
      navigate('/consultation');
      window.scrollTo(0, 0);
    } else {
      setSelectedPlan(plan);
      setShowPaymentModal(true);
    }
  };

  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Choose Your Automation Package</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Select the perfect automation solution tailored to your business needs. From essential workflows to comprehensive enterprise systems, we have the right package for you.
          </p>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {plans.map((plan, i) => (
            <div
              key={i}
              className={`
                relative p-8 rounded-3xl transition-all duration-300
                ${plan.featured ? 
                  'bg-gradient-to-b from-blue-900 to-purple-900 text-white scale-105 shadow-xl' : 
                  plan.isCustom ?
                  'bg-gradient-to-br from-purple-900 to-blue-900 text-white shadow-lg' :
                  'bg-white hover:-translate-y-2 shadow-lg'
                }
              `}
            >
              {plan.featured && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-yellow-400 text-black px-4 py-1 rounded-full text-sm font-semibold">
                  Most Popular
                </div>
              )}
              <h3 className="text-xl font-semibold mb-2">{plan.title}</h3>
              <p className="text-sm opacity-80 mb-4">{plan.description}</p>
              <div className="text-3xl font-bold mb-6">
                {plan.price}<span className="text-sm font-normal opacity-80">/month</span>
              </div>

              <div className="mb-6">
                <h4 className={`font-semibold mb-3 ${plan.featured || plan.isCustom ? 'text-yellow-400' : 'text-blue-900'}`}>
                  Included Automations:
                </h4>
                <ul className="space-y-3">
                  {plan.automations.map((feature, j) => (
                    <li key={j} className="flex items-start gap-3">
                      <span className={`text-lg mt-0.5 ${plan.featured || plan.isCustom ? 'text-yellow-400' : 'text-green-500'}`}>✓</span>
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mb-8">
                <h4 className={`font-semibold mb-3 ${plan.featured || plan.isCustom ? 'text-yellow-400' : 'text-blue-900'}`}>
                  Support & Services:
                </h4>
                <ul className="space-y-3">
                  {plan.support.map((feature, j) => (
                    <li key={j} className="flex items-start gap-3">
                      <span className={`text-lg mt-0.5 ${plan.featured || plan.isCustom ? 'text-yellow-400' : 'text-green-500'}`}>✓</span>
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <button
                onClick={() => handlePurchase(plan)}
                className={`
                  w-full py-4 rounded-full font-medium transition-all
                  ${plan.isCustom ?
                    'bg-white text-purple-900 hover:bg-gray-100' :
                    plan.featured ? 
                      'bg-yellow-400 text-black hover:bg-yellow-300' : 
                      'bg-gradient-to-r from-blue-900 to-purple-900 text-white hover:opacity-90'
                  }
                `}
              >
                {plan.isCustom ? 'Contact Sales Team' : 'Buy Now'}
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Payment Modal with iframe */}
      {showPaymentModal && selectedPlan && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-2xl shadow-xl max-w-md w-full p-6 relative">
            <button 
              onClick={() => setShowPaymentModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            
            <div className="text-center mb-6">
              <div className="bg-gradient-to-r from-blue-900 to-purple-900 text-white p-4 rounded-full inline-flex items-center justify-center mb-4">
                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m0 0v2m0-2h2m-2 0H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">{selectedPlan.title}</h3>
              <p className="text-gray-600 mb-6">
                Complete your purchase securely through Pesapal, our trusted payment processor.
              </p>
              
              {/* Pesapal iframe */}
              <div className="flex justify-center mb-6">
                <iframe 
                  width="300" 
                  height="60" 
                  src={selectedPlan.iframeUrl} 
                  frameBorder="0" 
                  allowFullScreen
                  title="Pesapal Payment"
                  className="mx-auto"
                />
              </div>
              
              <p className="text-sm text-gray-500">
                If the payment button doesn't appear, please try refreshing the page or contact our support team.
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Pricing; 