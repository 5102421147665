import React, { useState, useRef } from 'react';
import { submitConsultation } from './submitConsultation';
import ReCAPTCHA from 'react-google-recaptcha';

interface FormData {
  fullName: string;
  companyName: string;
  position: string;
  email: string;
  phone: string;
  contactMethod: 'email' | 'phone';
  industry: string;
  otherIndustry?: string;
  companySize: string;
  automationNeeds: string[];
  otherAutomationNeeds?: string;
  mainGoal: string;
  otherMainGoal?: string;
  challenges?: string;
  consultationTime: string;
  proposedDate: string;
  captchaValue: string | null;
}

const ConsultationForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    companyName: '',
    position: '',
    email: '',
    phone: '',
    contactMethod: 'email',
    industry: '',
    companySize: '',
    automationNeeds: [],
    mainGoal: '',
    consultationTime: '',
    proposedDate: '',
    captchaValue: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const industries = [
    'Financial Services',
    'Manufacturing',
    'Retail & E-commerce',
    'Healthcare',
    'Agriculture',
    'Logistics & Transportation',
    'Education',
    'Real Estate',
    'Freelancing Services',
    'Non-Profit',
    'Other'
  ];

  const companySizes = [
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201+ employees'
  ];

  const automationOptions = [
    'Customer Service',
    'Sales & Marketing',
    'Data Entry & Processing',
    'Financial Operations',
    'Inventory Management',
    'HR & Recruitment',
    'Social Media Management',
    'Content Creation',
    'Lead Generation',
    'Onboarding Processes',
    'Other'
  ];

  const goals = [
    'Reduce operational costs',
    'Improve efficiency',
    'Scale operations',
    'Enhance customer experience',
    'Reduce errors',
    'Other'
  ];

  const consultationTimes = [
    'Morning (9am - 12pm)',
    'Afternoon (2pm - 5pm)',
    'Flexible'
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.captchaValue) {
      setSubmitStatus({
        type: 'error',
        message: 'Please complete the reCAPTCHA verification.'
      });
      return;
    }

    // Prevent multiple submissions
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    setSubmitStatus({ type: null, message: '' });

    try {
      await submitConsultation(formData);
      
      setSubmitStatus({
        type: 'success',
        message: 'Thank you! We will contact you shortly to confirm your consultation.'
      });
      
      // Reset form
      setFormData({
        fullName: '',
        companyName: '',
        position: '',
        email: '',
        phone: '',
        contactMethod: 'email',
        industry: '',
        companySize: '',
        automationNeeds: [],
        mainGoal: '',
        consultationTime: '',
        proposedDate: '',
        captchaValue: null
      });

      recaptchaRef.current?.reset();
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus({
        type: 'error',
        message: 'There was an error submitting the form. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (value: string) => {
    setFormData(prev => ({
      ...prev,
      automationNeeds: prev.automationNeeds.includes(value)
        ? prev.automationNeeds.filter(need => need !== value)
        : [...prev.automationNeeds, value]
    }));
  };

  const handleCaptchaChange = (value: string | null) => {
    setFormData(prev => ({ ...prev, captchaValue: value }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 py-12">
      <div className="max-w-3xl mx-auto px-6">
        <h1 className="text-4xl font-bold mb-4 text-center text-white">
          We're Excited to Get Started Creating AI Systems for Your Business
        </h1>
        <p className="text-xl text-center mb-8 text-white/80">
          Please take a minute to fill out the form below and we'll get back to you shortly.
        </p>

        <form onSubmit={handleSubmit} className="bg-white rounded-2xl shadow-lg p-8 space-y-8">
          {/* Basic Information */}
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">Basic Information</h2>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name*
              </label>
              <input
                type="text"
                name="fullName"
                required
                value={formData.fullName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Name*
              </label>
              <input
                type="text"
                name="companyName"
                required
                value={formData.companyName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Position/Role*
              </label>
              <input
                type="text"
                name="position"
                required
                value={formData.position}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          {/* Contact Information */}
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">Contact Information</h2>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Business Email*
              </label>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number*
              </label>
              <input
                type="tel"
                name="phone"
                required
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Preferred contact method*
              </label>
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="contactMethod"
                    value="email"
                    checked={formData.contactMethod === 'email'}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Email
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="contactMethod"
                    value="phone"
                    checked={formData.contactMethod === 'phone'}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Phone
                </label>
              </div>
            </div>
          </div>

          {/* Business Overview */}
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">Business Overview</h2>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Industry*
              </label>
              <select
                name="industry"
                required
                value={formData.industry}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select an industry</option>
                {industries.map(industry => (
                  <option key={industry} value={industry}>{industry}</option>
                ))}
              </select>
              {formData.industry === 'Other' && (
                <input
                  type="text"
                  name="otherIndustry"
                  placeholder="Please specify"
                  value={formData.otherIndustry}
                  onChange={handleInputChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Size*
              </label>
              <select
                name="companySize"
                required
                value={formData.companySize}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select company size</option>
                {companySizes.map(size => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Automation Needs */}
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">Automation Needs</h2>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                What aspects of your business would you like to automate?*
              </label>
              <div className="space-y-2">
                {automationOptions.map(option => (
                  <label key={option} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.automationNeeds.includes(option)}
                      onChange={() => handleCheckboxChange(option)}
                      className="mr-2"
                    />
                    {option}
                  </label>
                ))}
              </div>
              {formData.automationNeeds.includes('Other') && (
                <input
                  type="text"
                  name="otherAutomationNeeds"
                  placeholder="Please specify"
                  value={formData.otherAutomationNeeds}
                  onChange={handleInputChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                What's your main goal for automation?*
              </label>
              <select
                name="mainGoal"
                required
                value={formData.mainGoal}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select main goal</option>
                {goals.map(goal => (
                  <option key={goal} value={goal}>{goal}</option>
                ))}
              </select>
              {formData.mainGoal === 'Other' && (
                <input
                  type="text"
                  name="otherMainGoal"
                  placeholder="Please specify"
                  value={formData.otherMainGoal}
                  onChange={handleInputChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Current challenges (optional)
              </label>
              <textarea
                name="challenges"
                value={formData.challenges}
                onChange={handleInputChange}
                maxLength={2000}
                placeholder="Briefly describe your biggest operational challenge"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-24"
              />
            </div>
          </div>

          {/* Consultation Scheduling */}
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">Consultation Scheduling</h2>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Preferred consultation time*
              </label>
              <select
                name="consultationTime"
                required
                value={formData.consultationTime}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select preferred time</option>
                {consultationTimes.map(time => (
                  <option key={time} value={time}>{time}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Proposed Date*
              </label>
              <input
                type="date"
                name="proposedDate"
                required
                value={formData.proposedDate}
                onChange={handleInputChange}
                min={new Date().toISOString().split('T')[0]}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <p className="mt-1 text-sm text-gray-500">
                Please select a date at least 24 hours in advance
              </p>
            </div>
          </div>

          {submitStatus.type && (
            <div 
              className={`p-4 rounded-lg mb-4 ${
                submitStatus.type === 'success' 
                  ? 'bg-green-50 text-green-800' 
                  : 'bg-red-50 text-red-800'
              }`}
            >
              {submitStatus.message}
            </div>
          )}

          <div className="flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              onChange={handleCaptchaChange}
              theme="light"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting || !formData.captchaValue}
            className={`w-full bg-gradient-to-r from-blue-900 to-purple-900 text-white py-3 rounded-lg font-semibold 
              ${(isSubmitting || !formData.captchaValue) ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90 transition-opacity'}`}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <svg 
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24"
                >
                  <circle 
                    className="opacity-25" 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    stroke="currentColor" 
                    strokeWidth="4"
                  />
                  <path 
                    className="opacity-75" 
                    fill="currentColor" 
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Submitting consultation request...
              </div>
            ) : (
              'Submit Consultation Request'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConsultationForm; 