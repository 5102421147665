import React from 'react';
import { Link } from 'react-router-dom';

const Hero: React.FC = () => {
  return (
    <section className="bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white py-20 relative overflow-hidden">
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-96 h-96 bg-white rounded-full filter blur-3xl transform -translate-x-1/2 -translate-y-1/2"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl transform translate-x-1/2 translate-y-1/2"></div>
      </div>
      
      <div className="max-w-7xl mx-auto px-6 relative z-10">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-6xl font-bold mb-8 leading-tight">
              Powerful Agentic AI Systems for Kenyan Businesses
            </h1>
            <p className="text-xl mb-10 text-white/80 leading-relaxed">
              Mufti Automaytion helps Kenyan businesses work smarter with automation systems powered by artificial intelligence. We connect your business apps and create custom AI systems that work together to handle complex tasks automatically. Our agentic AI solutions replace manual work, save time, improve quality, and create better experiences for your customers.
            </p>
            <div className="flex gap-4 mt-8">
              <Link
                to="/consultation"
                className="bg-yellow-400 text-black px-6 py-3 rounded-full hover:bg-yellow-300 transition-colors"
              >
                Book a Consultation
              </Link>
              <Link
                to="/white-paper"
                className="bg-gradient-to-r from-blue-900 to-purple-900 text-white px-6 py-3 rounded-full hover:opacity-90 transition-opacity"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="relative">
            <img 
              src="/hero-image.png" 
              alt="AI Automation Visualization" 
              className="w-full h-auto max-w-lg mx-auto
                transform hover:scale-105 transition-transform duration-500
                filter drop-shadow-2xl
                animate-float"
              loading="eager"
            />
            {/* Decorative glow effect */}
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-2xl -z-10"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 