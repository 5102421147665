import React, { useState, useRef } from 'react';
import Airtable from 'airtable';
import ReCAPTCHA from 'react-google-recaptcha';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const Contact: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    success?: boolean;
    message?: string;
  }>({});
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const WEBHOOK_URL = 'https://hook.eu2.make.com/a37c65uvytwdj99iygkkt8okxb2dy6o6';

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate reCAPTCHA
    if (!captchaValue) {
      setSubmitStatus({
        success: false,
        message: 'Please complete the reCAPTCHA verification.'
      });
      return;
    }
    
    setIsSubmitting(true);
    setSubmitStatus({});
    
    try {
      // Initialize Airtable with your existing API key
      const base = new Airtable({ 
        apiKey: process.env.REACT_APP_AIRTABLE_API_KEY 
      }).base(process.env.REACT_APP_AIRTABLE_BASE_ID as string);
      
      // Get the table name with a fallback
      const tableName = process.env.REACT_APP_AIRTABLE_TABLE_TWO_NAME || 'Inquiries';
      console.log('Using table name:', tableName); // Debug log
      
      // Save to the Airtable table (keeping existing functionality)
      const airtableResult = await base(tableName).create([
        {
          fields: {
            Name: formData.name,
            Email: formData.email,
            HowCanWeHelp: formData.message
          }
        }
      ]);
      
      // Call the webhook with the same data
      try {
        const webhookResponse = await fetch(WEBHOOK_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            message: formData.message,
            recaptchaToken: captchaValue,
            submissionTime: new Date().toISOString(),
            recordId: airtableResult[0].id // Include Airtable record ID for reference
          })
        });
        
        if (!webhookResponse.ok) {
          console.warn('Webhook submission warning:', await webhookResponse.text());
        } else {
          console.log('Webhook submission successful');
        }
      } catch (webhookError) {
        // Log webhook errors but don't affect the user experience
        console.warn('Webhook submission error:', webhookError);
      }
      
      setSubmitStatus({
        success: true,
        message: 'Thank you for your message! We will get back to you soon.'
      });
      
      // Reset form and reCAPTCHA
      setFormData({ name: '', email: '', message: '' });
      setCaptchaValue(null);
      recaptchaRef.current?.reset();
    } catch (error) {
      console.error('Error saving to Airtable:', error);
      setSubmitStatus({
        success: false,
        message: 'Something went wrong. Please try again or contact us directly.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <section className="py-20 bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 text-white">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Get in Touch</h2>
          <p className="text-xl opacity-90">We'd be delighted to hear from you</p>
        </div>
        
        <div className="max-w-2xl mx-auto bg-white/10 backdrop-blur-md p-8 rounded-3xl border border-white/20">
          {submitStatus.success ? (
            <div className="text-center py-8">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-500 mb-6">
                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h3 className="text-2xl font-bold mb-4">Message Sent!</h3>
              <p className="text-lg opacity-90">{submitStatus.message}</p>
              <button
                onClick={() => setSubmitStatus({})}
                className="mt-6 bg-white/20 hover:bg-white/30 px-6 py-2 rounded-full transition-colors"
              >
                Send Another Message
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-yellow-400 transition-colors"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-yellow-400 transition-colors"
                  required
                />
              </div>
              <div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  placeholder="How can we help you?"
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-yellow-400 transition-colors"
                  required
                />
              </div>
              
              {/* reCAPTCHA component */}
              <div className="flex justify-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                  onChange={handleCaptchaChange}
                  theme="dark"
                />
              </div>
              
              {submitStatus.message && !submitStatus.success && (
                <div className="bg-red-500/20 border border-red-500/30 rounded-lg p-4 text-center">
                  {submitStatus.message}
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting || !captchaValue}
                className={`w-full bg-yellow-400 text-black px-8 py-4 rounded-full font-semibold hover:bg-yellow-300 transform hover:-translate-y-1 transition-all shadow-lg ${
                  (isSubmitting || !captchaValue) ? 'opacity-70 cursor-not-allowed' : ''
                }`}
              >
                {isSubmitting ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Submitting...
                  </span>
                ) : (
                  'Submit'
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact; 