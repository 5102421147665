import React from 'react';

const Schedule: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-900 via-indigo-900 to-purple-900 py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">Schedule a Consultation</h1>
          <p className="text-xl text-white/80">
            Choose a convenient time for your personalized automation consultation
          </p>
        </div>

        {/* Calendar Container */}
        <div className="bg-white rounded-2xl shadow-xl p-8">
          <div className="flex justify-center">
            <iframe 
              src="https://calendar.zoho.com/eventreqForm/zz08011230e5ef6f19a5c626b61c0ba15d0003f60b0b2bc50b381ce5680210e76a1141f05b1223e045efb030ab0e9ec797bbc19a54?theme=0&l=en&tz=Africa%2FNairobi" 
              title="Consultation Calendar"
              frameBorder="0" 
              scrolling="no" 
              width="432" 
              height="350"
              className="w-full max-w-[432px]"
              style={{ 
                backgroundColor: 'transparent',
                margin: '0 auto'
              }}
            />
          </div>

          {/* Additional Information */}
          <div className="mt-8 text-gray-600">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">What to Expect</h2>
            <ul className="space-y-3">
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>30-minute personalized consultation</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>Discussion of your business automation needs</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>Tailored solutions and recommendations</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>Q&A session with our automation experts</span>
              </li>
            </ul>
          </div>

          {/* Notes */}
          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <p className="text-sm text-blue-800">
              <strong>Note:</strong> After scheduling, you'll receive a confirmation email with the meeting link and any additional information needed for the consultation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule; 