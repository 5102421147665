interface ConsultationData {
  fullName: string;
  companyName: string;
  position: string;
  email: string;
  phone: string;
  contactMethod: string;
  industry: string;
  otherIndustry?: string;
  companySize: string;
  automationNeeds: string[];
  otherAutomationNeeds?: string;
  mainGoal: string;
  otherMainGoal?: string;
  challenges?: string;
  consultationTime: string;
  proposedDate: string;
  captchaValue: string | null;
}

export const submitConsultation = async (formData: ConsultationData) => {
  const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;
  const AIRTABLE_BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;
  const AIRTABLE_TABLE_NAME = process.env.REACT_APP_AIRTABLE_TABLE_NAME;
  const WEBHOOK_URL = 'https://n8n-automaytion-u34512.vm.elestio.app/webhook/4cabfbb6-940d-4e4f-a52b-5aafbe1f0f4a';

  if (!AIRTABLE_API_KEY || !AIRTABLE_BASE_ID || !AIRTABLE_TABLE_NAME) {
    console.error('Missing environment variables:', {
      hasApiKey: !!AIRTABLE_API_KEY,
      hasBaseId: !!AIRTABLE_BASE_ID,
      hasTableName: !!AIRTABLE_TABLE_NAME
    });
    throw new Error('Missing Airtable configuration');
  }

  try {
    // Format the date to YYYY-MM-DD
    const formattedDate = formData.proposedDate ? new Date(formData.proposedDate).toISOString().split('T')[0] : '';

    // Prepare Airtable data (excluding captchaValue)
    const airtableFields = {
      fullName: formData.fullName,
      companyName: formData.companyName,
      position: formData.position,
      email: formData.email,
      phone: formData.phone,
      contactMethod: formData.contactMethod,
      industry: formData.industry,
      industryOther: formData.industry === 'Other' ? formData.otherIndustry : '',
      companySize: formData.companySize,
      automationNeeds: formData.automationNeeds,
      automationNeedsOther: formData.automationNeeds.includes('Other') ? formData.otherAutomationNeeds : '',
      mainGoal: formData.mainGoal,
      mainGoalOther: formData.mainGoal === 'Other' ? formData.otherMainGoal : '',
      challenges: formData.challenges || '',
      consultationTime: formData.consultationTime,
      proposedDate: formattedDate,
    };

    // Submit to Airtable
    const airtableResponse = await fetch(`https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${AIRTABLE_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        records: [
          {
            fields: airtableFields
          }
        ]
      })
    });

    if (!airtableResponse.ok) {
      const errorData = await airtableResponse.json();
      console.error('Airtable Error Response:', errorData);
      throw new Error(`Failed to submit to Airtable: ${airtableResponse.status} ${airtableResponse.statusText}`);
    }

    const airtableResult = await airtableResponse.json();
    console.log('Airtable submission successful:', airtableResult);

    // Enhanced webhook logging
    console.log('Attempting webhook submission to:', WEBHOOK_URL);
    
    try {
      const webhookData = {
        ...airtableFields,
        recaptchaToken: formData.captchaValue,
        airtableRecordId: airtableResult.records[0].id,
        submissionTime: new Date().toISOString()
      };
      
      console.log('Webhook payload:', webhookData);

      const webhookResponse = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(webhookData)
      });

      if (!webhookResponse.ok) {
        const errorText = await webhookResponse.text();
        console.warn('Webhook Error Response:', {
          status: webhookResponse.status,
          statusText: webhookResponse.statusText,
          response: errorText
        });
      } else {
        const responseData = await webhookResponse.text();
        console.log('Webhook Success Response:', {
          status: webhookResponse.status,
          response: responseData
        });
      }
    } catch (webhookError) {
      console.warn('Webhook submission error:', {
        error: webhookError,
        url: WEBHOOK_URL
      });
    }

    return airtableResult;
  } catch (error) {
    console.error('Submission Error:', error);
    throw error;
  }
}; 